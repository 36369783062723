<template>
  <button
    type="button"
    class="item"
    :class="{ 'item--danger': isDanger }"
    @click="emitClick"
    :disabled="disabled"
  >
    <slot name="icon"></slot>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "EllipsisMenuItem",

  props: {
    isDanger: Boolean,
    disabled: Boolean
  },

  methods: {
    emitClick(e) {
      this.$emit("click", e);
    }
  }
};
</script>

<style scoped>
.item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--lengthSm3);
  padding: var(--lengthSm3);
  color: var(--fontColor2);
  font-weight: var(--medium);
  transition: background-color 150ms;
}

.item:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.item:not(:disabled):hover {
  color: var(--fontColor1);
  background-color: var(--grayColor1);
}

.item:first-child {
  border-top-left-radius: var(--lengthSm1);
  border-top-right-radius: var(--lengthSm1);
}

.item:last-child {
  border-bottom-left-radius: var(--lengthSm1);
  border-bottom-right-radius: var(--lengthSm1);
}

.item + .item {
  border-top: 1px solid var(--grayColor2);
}

.item--danger {
  color: var(--dangerColor);
}

.item--danger:not(:disabled):hover {
  color: var(--dangerColor);
}
</style>
