<template>
  <span class="header-selector" v-click-outside="close">
    <button type="button" @click="toggleMenu">
      <font-awesome-icon :icon="icons.chevronDown" size="sm" />
    </button>
    <div v-show="showMenu" class="header-menu">
      <custom-button type="button" variant="secondary" @click="toggleCheck">
        {{
          selectedOptions.length === options.length
            ? "Desmarcar Todos"
            : "Marcar Todos"
        }}
      </custom-button>
      <checkbox
        v-for="option in options"
        :key="option.name"
        v-model="selectedOptions"
        :value="option.name"
        :label="option.value || option.name"
      />
    </div>
  </span>
</template>

<script>
import vClickOutside from "v-click-outside";
import Checkbox from "@/components/Checkbox.vue";
import CustomButton from "@/components/CustomButton.vue";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default {
  components: { Checkbox, CustomButton },
  name: "FilterDropdown",

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    showMenu: false,
    selectedOptions: [],

    icons: {
      chevronDown: faChevronDown
    }
  }),

  props: {
    options: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  watch: {
    selectedOptions() {
      this.$emit("optionSelected", {
        name: this.name,
        options: this.selectedOptions
      });
    }
  },

  methods: {
    close() {
      this.showMenu = false;
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    toggleCheck() {
      if (this.selectedOptions.length) this.selectedOptions = [];
      else this.selectedOptions = this.options.map(o => o.name);
    }
  }
};
</script>

<style scoped>
.header-selector button:not(.btn) {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: inherit;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.header-menu {
  position: absolute;
  top: calc(100% + 1.3em);
  left: 0;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthSm3);
  gap: var(--lengthSm3);
  padding: var(--lengthSm3);
  background-color: white;
  border: solid 1px var(--grayColor2);
  border-bottom-left-radius: var(--lengthSm1);
  border-bottom-right-radius: var(--lengthSm1);
  overflow: auto;
  z-index: 1000;
}

.header-menu > .btn {
  flex-shrink: 0;
}
</style>
