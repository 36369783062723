<template>
  <modal-2 size="lg" ref="modal">
    <template slot="title">Carga Masiva de Usuarios</template>

    <custom-form @submit="onSubmit">
      <custom-button
        :href="downloadLink"
        variant="primary"
        download="carga-masiva-usuarios.csv"
      >
        <font-awesome-icon :icon="downloadIcon" slot="icon" />
        Descargar Plantilla
      </custom-button>

      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th>Código de Identidad</th>
              <th>Nombre Completo</th>
              <th>Email</th>
              <th v-for="filter in filters" :key="filter.id">
                {{ filter.name }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(user, index) in addedUsers" :key="index">
              <td>
                <custom-input
                  v-model="user.doc"
                  placeholder="Documento de Identidad"
                  :rules="
                    `required|excluded:${addedUsers
                      .filter((u, i) => index !== i)
                      .map(u => u.doc)},${users.map(u => u.doc)}|min:6`
                  "
                />
              </td>
              <td>
                <custom-input
                  v-model="user.name"
                  placeholder="Nombre Completo"
                  rules="required"
                />
              </td>
              <td>
                <custom-input
                  v-model="user.email"
                  placeholder="Email"
                  :rules="
                    `excluded:${addedUsers
                      .filter((u, i) => index !== i)
                      .map(u => u.email)},${users.map(u => u.email)}`
                  "
                />
              </td>
              <td v-for="filter in filters" :key="filter.id">
                <custom-select
                  v-model="user.filters[filter.name]"
                  :options="getFilterOptions(user, filter)"
                  rules="required"
                />
              </td>
              <td>
                <icon-button
                  icon="times"
                  label="Eliminar"
                  variant="secondary"
                  @click="deleteUser(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="!addedUsers.length" class="placeholder">
          <file-uploader @input="parseFile" />
        </div>
      </div>

      <template v-if="addedUsers.length" slot="buttons">
        <custom-button type="button" variant="secondary" @click="onCancel">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary" :disabled="isLoading">
          {{ isLoading ? "Agregando..." : "Agregar" }}
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Papa from "papaparse";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import CustomButton from "../../components/CustomButton.vue";
import FileUploader from "../../components/FileUploader.vue";
import Modal2 from "../../components/Modal2.vue";
import IconButton from "../../components/IconButton.vue";
import CustomForm from "../../components/CustomForm.vue";
import CustomInput from "../../components/CustomInput.vue";
import CustomSelect from "../../components/CustomSelect.vue";

export default {
  name: "BatchAddUsersModal",

  components: {
    CustomButton,
    FileUploader,
    Modal2,
    IconButton,
    CustomForm,
    CustomInput,
    CustomSelect
  },

  data: () => ({
    downloadIcon: faFileDownload,

    addedUsers: [],

    isLoading: false
  }),

  computed: {
    ...mapState({
      users: ({ users }) => users.users,
      filters: ({ filters }) => filters.filters
    }),

    downloadLink() {
      return this.buildUsersCsv();
    },

    getFilterOptions() {
      return (user, filter) => {
        if (filter.hasParent) {
          return filter.options
            .filter(
              o =>
                o.parentOption ===
                user.filters[this.getFilterName(filter.parentId)]
            )
            .map(o => o.name);
        } else {
          return filter.options.map(o => o.name);
        }
      };
    }
  },

  methods: {
    ...mapActions("users", ["addMultipleUsers"]),

    ...mapMutations(["setAlert"]),

    getFilterName(id) {
      return this.filters.find(f => f.id === id).name || "";
    },

    getFileExtension(filename) {
      return (
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
        filename
      );
    },

    parseFile(file) {
      if (!file) return;

      if (this.getFileExtension(file.name) !== "csv") {
        this.setAlert({
          state: "error",
          message: "El tipo de archivo debe ser CSV"
        });
        return;
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: header => {
          switch (header.toLowerCase()) {
            case "código de identidad":
              return "doc";
            case "nombre completo":
              return "name";
            case "email":
              return "email";
            default:
              return header.trim();
          }
        },
        transform: value => value.trim(),

        // step: (row) => {
        //   console.log(row.data);

        //   let { doc, name, email, ...filters } = row.data;

        //   filters = Object.keys(filters)
        //     .filter((k) => this.filters.find(({ name }) => name === k))
        //     .reduce((o, k) => ({ ...o, [k]: filters[k] }), {});

        //   filters = Object.entries(filters)
        //     .map((f) => {
        //       const filter = this.filters.find(({ name }) => name === f[0]);

        //       const filterOptions = filter.options.map((o) => o.name);

        //       // se verifica si la opción elegida para ese filtro se encuentra
        //       // dentro de las opciones válidas
        //       return filterOptions.includes(f[1]) ? f : [f[0], ''];
        //     })
        //     // se retorna al formato de objeto luego de haberlo transformado a array
        //     .reduce((a, o) => ({ ...a, [o[0]]: o[1] }), {});

        //   this.addedUsers.push({
        //     doc,
        //     name,
        //     email,
        //     filters,
        //   });
        // },

        complete: results => {
          if (!results) return;

          let users = results.data.map(({ doc, name, email, ...filters }) => ({
            doc,
            name,
            email,
            //filter out filter names which are not in created filters
            filters: Object.keys(filters)
              .filter(k => this.filters.find(({ name }) => name === k))
              .reduce((o, k) => ({ ...o, [k]: filters[k] }), {})
          }));

          users = users.map(u => {
            const userFilters = Object.entries(u.filters)
              .map(f => {
                const filter = this.filters.find(({ name }) => name === f[0]);

                const filterOptions = filter.options.map(o => o.name);

                // se verifica si la opción elegida para ese filtro se encuentra
                // dentro de las opciones válidas
                return filterOptions.includes(f[1]) ? f : [f[0], ""];
              })
              // se retorna al formato de objeto luego de haberlo transformado a array
              .reduce((a, o) => ({ ...a, [o[0]]: o[1] }), {});

            return { ...u, filters: userFilters };
          });

          this.addedUsers = users;
        }
      });
    },

    deleteUser(index) {
      this.addedUsers.splice(index, 1);
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        await this.addMultipleUsers(this.addedUsers);

        this.setAlert({
          state: "success",
          message: "Se agregaron los usuarios"
        });

        this.onCancel();
      } catch (err) {
        console.log(err);

        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });
      } finally {
        this.isLoading = false;
      }
    },

    openModal() {
      this.$refs.modal.open();
    },

    onCancel() {
      this.addedUsers = [];
      this.$refs.modal.close();
    },

    buildUsersCsv() {
      let matrix = [
        "Código de Identidad",
        "Nombre Completo",
        "Email",
        ...this.filters.map(f => f.name)
      ];

      const csvContent = "\uFEFF" + matrix;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      return URL.createObjectURL(blob);
    }
  }
};
</script>

<style scoped>
.table-container {
  height: 600px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
  overflow: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
th button {
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--semi-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

th:first-child {
  border-top-left-radius: var(--lengthSm2);
}

th:last-child {
  border-top-right-radius: var(--lengthSm2);
}

th,
td {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
  text-align: start;
  white-space: nowrap;
  border-bottom: 1px solid var(--grayColor2);
}

th,
td:last-child {
  position: sticky;
}

th {
  top: 0;
  z-index: 997;
}

th:last-child,
td:last-child {
  right: 0;
  width: calc(28px + var(--lengthSm3) * 2);
}

td:last-child {
  background-color: white;
}

th:not(:last-child),
td:not(:last-child) {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.placeholder {
  flex-grow: 1;
  display: grid;
  place-items: center;
}
</style>
