<template>
  <div v-click-outside="close" class="ellipsis-menu">
    <icon-button
      :icon="ellipsisIcon"
      variant="secondary"
      label="More"
      @click="toggle"
    />

    <transition name="menu">
      <ul v-show="isOpen" class="ellipsis-menu__menu" @click.stop="close">
        <slot></slot>
      </ul>
    </transition>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import IconButton from "./IconButton.vue";

export default {
  components: { IconButton },
  name: "EllipsisMenu",

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    isOpen: false,
    ellipsisIcon: faEllipsisV
  }),

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    toggle() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style scoped>
.ellipsis-menu {
  position: relative;
}

.ellipsis-menu__trigger:hover {
  background-color: var(--grayColor1);
}

.ellipsis-menu__trigger:focus {
  background-color: var(--grayColor2);
}

.ellipsis-menu__menu {
  position: absolute;
  top: 110%;
  right: 0;
  width: 175px;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid var(--grayColor2);
  box-shadow: 1px 1px 5px 2px var(--grayColor1);
  border-radius: var(--lengthSm1);
  z-index: 999;
}

.menu-enter,
.menu-leave-to {
  opacity: 0;
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 150ms;
}
</style>
