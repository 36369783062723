<template>
  <div v-if="!loading">
    <view-hero>
      <template slot="title">Usuarios</template>
      <template slot="description">
        ELHA permite comunicarte con todos los trabajadores de la organización.
        Aquí, puedes incluir sus datos y enviar la información de la encuesta de
        clima laboral.
      </template>
    </view-hero>

    <div class="btn-container">
      <div>
        <div>
          <span class="stat">
            {{ `Avance Total: ${finishedPercentage}%` }}
          </span>
          <span class="stat">{{
            `Terminados: ${users.filter(u => u.progress === 1).length}`
          }}</span>
          <span class="stat">
            {{
              `Faltantes: ${users.length -
                users.filter(u => u.progress === 1).length}`
            }}
          </span>
        </div>

        <div>
          <period-select
            :surveys="surveys"
            :selectedSurveyName="survey.name"
            @input="changeSurvey"
          />

          <custom-button
            variant="button"
            download="lista-usuarios.csv"
            :href="downloadLink"
          >
            Excel
            <font-awesome-icon :icon="icons.excel" slot="icon" />
          </custom-button>
        </div>
      </div>

      <div>
        <search-input v-model="search" placeholder="Buscar usuario" />

        <custom-button
          v-show="selectedUsers.length"
          type="button"
          variant="secondary"
          @click="onBatchDelete"
        >
          Eliminar
          <font-awesome-icon slot="icon" icon="trash-alt" />
        </custom-button>

        <custom-button
          v-show="selectedUsers.length"
          type="button"
          variant="secondary"
          @click="sendMessage"
          :disabled="isSendingEmail"
        >
          {{ isSendingEmail ? "Enviando..." : "Enviar Correo" }}
          <font-awesome-icon slot="icon" :icon="icons.email" />
        </custom-button>

        <custom-button
          v-if="!survey.active"
          variant="secondary"
          @click="addFilter"
          :disabled="!survey.current"
        >
          Nuevo Filtro
          <font-awesome-icon slot="icon" icon="plus" />
        </custom-button>

        <custom-button
          variant="secondary"
          @click="openBatchAddModal"
          :disabled="!survey.current"
        >
          Carga Masiva
          <font-awesome-icon slot="icon" :icon="icons.import" />
        </custom-button>

        <custom-button
          variant="primary"
          @click="createUser"
          :disabled="!survey.current"
        >
          Nuevo Usuario
          <font-awesome-icon slot="icon" icon="plus" />
        </custom-button>
      </div>
    </div>

    <custom-form class="table-container" @submit="onSubmit">
      <table class="table">
        <thead>
          <tr>
            <th><checkbox v-model="selectAll" /></th>
            <th>#</th>
            <th>Código de Identidad</th>
            <th>Nombre Completo</th>
            <th>
              <div class="header-filters">
                <p>Email</p>
                <filter-dropdown
                  :options="[{ name: 'CON CORREO' }, { name: 'SIN CORREO' }]"
                  :name="'email'"
                  @optionSelected="changeSelectedOptions"
                />
              </div>
            </th>
            <th v-for="filter in filters" :key="filter.id">
              <div class="header-filters">
                <component
                  :is="survey.active ? 'div' : 'button'"
                  type="button"
                  @click="editFilter(filter)"
                >
                  {{ filter.name }}
                </component>
                <filter-dropdown
                  :options="filter.options"
                  :name="filter.name"
                  @optionSelected="changeSelectedOptions"
                />
              </div>
            </th>
            <th>Env&iacute;os</th>
            <th>
              <div class="header-filters">
                <p>Progreso</p>
                <filter-dropdown
                  :options="[{ name: '< 100%' }, { name: '100%' }]"
                  :name="'progress'"
                  @optionSelected="changeSelectedOptions"
                />
              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="isAddingUser" v-click-outside="cancelCreateUser">
            <td></td>
            <td></td>
            <td>
              <custom-input
                v-model="newUser.doc"
                name="código"
                placeholder="Documento"
                rules="required|min:6"
              />
            </td>
            <td>
              <custom-input
                v-model="newUser.name"
                placeholder="Nombre"
                rules="required"
              />
            </td>
            <td>
              <custom-input
                v-model="newUser.email"
                type="email"
                placeholder="Email"
              />
            </td>
            <td v-for="filter in filters" :key="filter.id">
              <custom-select
                v-model="newUser.filters[filter.name]"
                :options="getFilterOptions(filter)"
                rules="required"
              />
            </td>
            <td>-</td>
            <td>-</td>
            <td>
              <custom-button
                variant="primary"
                type="submit"
                :disabled="isLoading"
              >
                {{ isLoading ? "Agregando..." : "Agregar" }}
              </custom-button>
            </td>
          </tr>

          <template v-for="(user, index) in filteredUsers">
            <tr
              v-if="editUserCopy.id === user.id"
              :key="user.id"
              v-click-outside="cancelEditUser"
            >
              <td></td>
              <td>{{ index + 1 }}</td>
              <td>
                <custom-input
                  v-model="editUserCopy.doc"
                  placeholder="Documento"
                  :rules="
                    `required|min:6|excluded:${users
                      .filter(u => u.id !== editUserCopy.id)
                      .map(u => u.doc)}`
                  "
                />
              </td>
              <td>
                <custom-input
                  v-model="editUserCopy.name"
                  placeholder="Nombre"
                  rules="required"
                />
              </td>
              <td>
                <custom-input
                  v-model="editUserCopy.email"
                  type="email"
                  placeholder="Email"
                  :rules="
                    `excluded:${users
                      .filter(u => u.id !== editUserCopy.id)
                      .map(u => u.email)}`
                  "
                />
              </td>
              <td v-for="filter in filters" :key="filter.id">
                <custom-select
                  v-model="editUserCopy.filters[filter.name]"
                  :options="getFilterOptions(filter)"
                  rules="required"
                />
              </td>
              <td>-</td>
              <td>-</td>
              <td>
                <custom-button
                  variant="primary"
                  type="submit"
                  :disabled="isLoading"
                >
                  {{ isLoading ? "Guardando..." : "Guardar" }}
                </custom-button>
              </td>
            </tr>
            <tr
              v-else
              :key="user.id"
              :class="{ selected: selectedUsers.includes(user.id) }"
            >
              <td>
                <checkbox :value="user.id" v-model="selectedUsers" />
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ user.doc }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td v-for="filter in filters" :key="filter.id">
                {{ user.filters[filter.name] }}
              </td>
              <td>
                {{ survey.sentEmails ? survey.sentEmails[user.email] || 0 : 0 }}
              </td>
              <td>{{ `${(user.progress || 0) * 100}%` }}</td>
              <td>
                <ellipsis-menu>
                  <ellipsis-menu-item
                    @click="editUser(user)"
                    :disabled="survey.active"
                  >
                    <font-awesome-icon slot="icon" icon="pen" />
                    Editar
                  </ellipsis-menu-item>
                  <ellipsis-menu-item
                    :isDanger="true"
                    @click="onDelete(user.id)"
                  >
                    <font-awesome-icon slot="icon" icon="trash-alt" />
                    Eliminar
                  </ellipsis-menu-item>
                </ellipsis-menu>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <empty-screen
        v-if="!users.length && !isAddingUser"
        :icon="icons.emptyScreen"
        @ctaClick="createUser"
      >
        <template slot="title">Agrega un Usuario</template>
        <template slot="description">
          Agrega tu primer usuario presionando el botón inferior.
        </template>
        <template slot="btnText">Agregar Usuario</template>
      </empty-screen>
    </custom-form>

    <filter-modal ref="filterModal" />

    <batch-add-users-modal ref="batchAddModal" />
  </div>

  <div class="loading-screen" v-else-if="loading"><loading-screen /></div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import {
  faEnvelope,
  faFileImport,
  faMinusSquare,
  faUsers,
  faChevronDown,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons";

import vClickOutside from "v-click-outside";

import SearchInput from "../../components/SearchInput.vue";
import Checkbox from "../../components/Checkbox.vue";
import CustomButton from "../../components/CustomButton.vue";
import BatchAddUsersModal from "./BatchAddUsersModal.vue";
import FilterModal from "./FilterModal.vue";
import CustomInput from "../../components/CustomInput.vue";
import CustomForm from "../../components/CustomForm.vue";
import EllipsisMenu from "../../components/EllipsisMenu.vue";
import EllipsisMenuItem from "../../components/EllipsisMenuItem.vue";
import EmptyScreen from "../EmptyScreen.vue";
import ViewHero from "../../components/ViewHero.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import FilterDropdown from "@/components/FilterDropdown.vue";
import PeriodSelect from "@/components/dashboard/PeriodSelect.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "Users",

  components: {
    CustomButton,
    Checkbox,
    SearchInput,
    BatchAddUsersModal,
    FilterModal,
    CustomInput,
    CustomForm,
    EllipsisMenu,
    EllipsisMenuItem,
    EmptyScreen,
    ViewHero,
    CustomSelect,
    FilterDropdown,
    PeriodSelect,
    LoadingScreen
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    loading: false,
    selectedUsers: [],
    selectAll: false,
    search: "",
    selectedOptions: {},
    selectedProgress: [],
    selectedEmails: [],

    icons: {
      import: faFileImport,
      email: faEnvelope,
      deleteColumn: faMinusSquare,
      emptyScreen: faUsers,
      chevronDown: faChevronDown,
      excel: faFileExcel
    },

    isAddingUser: false,
    newUser: {},

    isEditingUser: false,
    editUserCopy: {},

    isLoading: false,
    isSendingEmail: false
  }),

  computed: {
    ...mapState({
      users: state => state.users.users,
      survey: state => state.survey,
      filters: state => state.filters.filters,
      surveys: state => state.surveys,
      company: state => state.company
    }),

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    filteredUsers() {
      return this.users.filter(({ name, doc, email, filters, progress }) => {
        let valid = true;
        Object.keys(filters).forEach(key => {
          if (key in this.selectedOptions) {
            if (
              !(
                !this.selectedOptions[key].length ||
                this.selectedOptions[key].includes(filters[key])
              )
            )
              valid = false;
          }
        });

        if (this.selectedProgress.length === 1) {
          if (
            !(this.selectedProgress[0] === "< 100%"
              ? (progress || 0) !== 1
              : !((progress || 0) < 1))
          )
            valid = false;
        }

        if (this.selectedEmails.length === 1) {
          if (this.selectedEmails[0] === "CON CORREO" && !email) valid = false;
          else if (this.selectedEmails[0] === "SIN CORREO" && email)
            valid = false;
        }

        return (
          valid &&
          (this.search
            ? [
                name,
                doc,
                email,
                (this.survey.sentEmails
                  ? this.survey.sentEmails[email] || 0
                  : 0) + "",
                `${(progress || 0) * 100}%`
              ].some(prop => prop.toLowerCase() === this.search.toLowerCase())
            : true)
        );
      });
    },

    finishedPercentage() {
      if (this.filteredUsers.length <= 0) return 0;
      const finished =
        (this.filteredUsers.filter(u => u.progress === 1).length /
          this.filteredUsers.length) *
        100;
      return Math.round(finished);
    }
  },

  watch: {
    selectAll() {
      this.selectAll
        ? (this.selectedUsers = this.filteredUsers.map(user => user.id))
        : (this.selectedUsers = []);
      // this.selectAll
      // ? (this.selectedUsers = this.filteredUsers.map(user => user.id).slice(0, 10))
      // : (this.selectedUsers = []);
    }
  },

  methods: {
    ...mapActions("users", [
      "deleteUser",
      "fetchUsers",
      "batchDelete",
      "addUser",
      "updateUser"
    ]),

    ...mapActions("filters", ["fetchFilters"]),

    ...mapActions(["sendBatchEmail", "fetchAllSurveys"]),

    ...mapMutations(["setAlert", "setCurrentSurvey"]),

    changeSelectedOptions(filter) {
      if (filter.name === "progress") {
        this.selectedProgress = [...filter.options];
      } else if (filter.name === "email") {
        this.selectedEmails = [...filter.options];
      } else {
        let temp = { ...this.selectedOptions };
        temp[filter.name] = [...filter.options];
        this.selectedOptions = { ...temp };
      }
    },

    CSVtoArray(text) {
      var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      var a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a.join(" ");
    },

    getReportCSV() {
      let csv = [];
      let temp = ["CÓDIGO DE IDENTIDAD", "NOMBRE COMPLETO", "EMAIL"];
      this.filters.forEach(filter => {
        temp.push(filter.name);
      });
      temp.push("ENVÍOS", "PROGRESO");
      csv.push(temp);

      temp = [];
      this.users.forEach(user => {
        temp.push("\n" + user.doc, user.name, user.email);
        this.filters.forEach(filter =>
          temp.push(this.CSVtoArray(user.filters[filter.name]))
        );
        temp.push(
          this.survey.sentEmails ? this.survey.sentEmails[user.email] || 0 : 0,
          (user.progress || 0) * 100 + "%"
        );
      });
      csv.push(temp);

      return csv;
    },

    getFilterName(id) {
      return this.filters.find(f => f.id === id).name || "";
    },

    addFilter() {
      if (this.survey.active) return;
      this.$refs.filterModal.add();
    },

    editFilter(filter) {
      if (this.survey.active) return;
      this.$refs.filterModal.edit(JSON.parse(JSON.stringify(filter)));
    },

    getFilterOptions(filter) {
      if (filter.hasParent) {
        return filter.options
          .filter(
            o =>
              o.parentOption ===
              this[this.isEditingUser ? "editUserCopy" : "newUser"].filters[
                this.getFilterName(filter.parentId)
              ]
          )
          .map(o => o.name);
      } else {
        return filter.options.map(o => o.name);
      }
    },

    createUser() {
      this.newUser = {
        doc: "",
        name: "",
        email: "",
        filters: this.filters.reduce(
          (acc, f) => ({ ...acc, [f.name]: undefined }),
          {}
        )
      };
      this.isAddingUser = true;
    },

    cancelCreateUser() {
      this.isAddingUser = false;
      this.newUser = {};
    },

    editUser(user) {
      if (this.survey.active) return;
      this.isEditingUser = true;
      this.editUserCopy = JSON.parse(JSON.stringify(user));
    },

    cancelEditUser() {
      this.isEditingUser = false;
      this.editUserCopy = {};
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        let message;
        if (this.isEditingUser) {
          await this.updateUser(this.editUserCopy);
          this.cancelEditUser();
          message = "Se guardó los cambios";
        } else {
          await this.addUser(this.newUser);
          this.cancelCreateUser();
          message = "Se creó el usuario";
        }

        this.setAlert({
          state: "success",
          message
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });
      } finally {
        this.isLoading = false;
      }
    },

    openBatchAddModal() {
      this.$refs.batchAddModal.openModal();
    },

    async onDelete(userId) {
      const confirmMsg = "¿Está seguro?\nEsta operación no puede revertirse.";

      if (!confirm(confirmMsg)) return;

      try {
        await this.deleteUser(userId);

        this.setAlert({
          state: "success",
          message: "Usuario eliminado."
        });
      } catch (error) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error. Por favor, vuelta a intentarlo."
        });
      }
    },

    async onBatchDelete() {
      if (this.selectedUsers.length > 10) {
        this.setAlert({
          state: "error",
          message: "Solo puede eliminar hasta 10 usuarios a la vez"
        });
        return;
      }

      if (!confirm("¿Está seguro?\nEsta operación no puede revertirse."))
        return;

      try {
        await this.batchDelete(this.selectedUsers);

        this.selectedUsers = [];
        this.selectAll = false;

        this.setAlert({
          state: "success",
          message: "Usuarios eliminados"
        });
      } catch (error) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error. Por favor, vuelta a intentarlo."
        });
      }
    },

    async sendMessage() {
      try {
        this.isSendingEmail = true;
        const selectedUsers = this.users
          .filter(u => this.selectedUsers.includes(u.id))
          .map(({ email, name, id, doc }) => ({ email, name, id, doc }));

        if (selectedUsers.some(u => !u.email)) {
          this.setAlert({
            state: "error",
            message: "Uno de los usuarios elegidos no tiene correo"
          });

          return;
        }

        await this.sendBatchEmail(selectedUsers);

        this.setAlert({
          state: "success",
          message: "Correos enviados"
        });

        this.selectAll = false;
        this.selectedUsers = [];
      } catch (err) {
        this.setAlert({
          state: "error",
          message:
            err.message || "Ocurrió un error, por favor inténtalo de nuevo"
        });
      } finally {
        this.isSendingEmail = false;
      }
    },

    async changeSurvey(survey) {
      this.loading = true;
      await this.setCurrentSurvey(survey);
      await this.fetchFilters();
      await this.fetchUsers();
      this.selectedUsers = [];
      this.loading = false;
    }
  },

  async mounted() {
    try {
      await this.fetchUsers();
      await this.fetchFilters();
      await this.fetchAllSurveys(this.company.id);
    } catch (error) {
      console.log(error);
      this.setAlert({
        state: "error",
        message: "Ocurrió un error, por favor vuelve a cargar la página"
      });
    }

    this.filters.forEach(filter => {
      this.selectedOptions[filter.name] = [];
    });
  },

  async beforeDestroy() {
    let survey = this.surveys.filter(survey => survey.current);
    await this.changeSurvey(survey[0]);
  }
};
</script>

<style scoped>
.btn-container {
  display: flex;
  flex-flow: column;
  gap: var(--lengthSm3);
}

.btn-container div {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  background-color: white;
  display: flex;
  justify-content: flex-start;
}

.btn-container > div {
  width: 100%;
}

.btn-container > div:first-child {
  justify-content: space-between;
}

.btn-container .search-input {
  margin-right: auto;
}

.table-container {
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-direction: row;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
  overflow: auto;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

th,
th button {
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--semi-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

th:first-child {
  border-top-left-radius: var(--lengthSm2);
}

th:last-child {
  border-top-right-radius: var(--lengthSm2);
}

th:not(:last-child, :nth-last-child(2)),
td:not(:last-child, :nth-last-child(2)) {
  border-right: 1px solid var(--grayColor2);
}

td:not(:first-child, :nth-child(2), :nth-last-child(3), :nth-last-child(2), :last-child) {
  min-width: 200px;
}

th,
td {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
  text-align: start;
  white-space: nowrap;
  border-bottom: 1px solid var(--grayColor2);
}

td {
  color: var(--fontColor2);
}

th,
td:last-child,
td:first-child {
  position: sticky;
  z-index: 995;
}

th {
  top: 0;
  z-index: 996;
}

th:first-child,
th:last-child {
  z-index: 997;
}

th:last-child,
td:last-child {
  right: 0;
  width: calc(28px + var(--lengthSm3) * 2);
  border-left: 1px solid var(--grayColor2);
}

th:first-child,
td:first-child {
  left: 0;
  width: calc(18px + var(--lengthSm3) * 2);
}

td:last-child,
td:first-child {
  background-color: white;
}

.selected {
  background-color: var(--grayColor1) !important;
}

.stat {
  padding: var(--lengthSm3);
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--semi-bold);
  text-align: start;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: var(--grayColor1);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
}

.errors {
  margin-top: 2px;
  color: var(--dangerColor);
  font-size: 0.75rem;
}

.header-filters {
  display: flex;
  align-items: center;
  gap: 0.8em;
  position: relative;
}

.hero {
  background-image: url("../../assets/modules/users.jpg");
}

.loading-screen {
  flex-grow: 1;
}
</style>
